<!-- eslint-disable max-len -->
<script>
import 'animate.css';
import { mapState, mapMutations } from 'vuex';
import TriviaQuestion from '../components/TriviaQuestion.vue';
import RestartButton from '../components/RestartButton.vue';
import IndexedDB from '../../utils/indexedDB';

export default {
  name: 'TriviaView',
  components: {
    TriviaQuestion,
    RestartButton,
  },
  data: () => ({
    counter: 1,
    disabled: false,
    isCorrect: false,
    modalText: '',
    questions: [],
    questionsAmount: 4,
    retries: 0,
    retriesGlobal: 0,
    showModal: false,
    showRetryModal: false,
    firsttime: true,
  }),
  computed: {
    ...mapState(['uuid', 'startTime']),
    cssVars() {
      const percent = `${(this.counter / this.questionsAmount) * 100}%`;
      return {
        '--background-width': percent,
      };
    },
  },
  methods: {
    ...mapMutations(['setUUID', 'setStartTime']),
    async restartTrivia() {
      const indexed = process.env.VUE_APP_INDEXED_DB;

      await IndexedDB.updateEntries({
        name: indexed,
        user: this.uuid,
        data: {
          completionReason: 'restarted',
          retries: this.retriesGlobal,
        },
      });

      const algo2 = await IndexedDB.getIndexedDB(indexed);
      console.log(algo2);

      this.$router.push('/');
    },
    activeModal({ copy, isCorrect }) {
      if (this.counter <= this.questionsAmount) {
        this.showModal = true;
        this.modalText = copy;
        this.isCorrect = isCorrect;
        this.disabled = true;
      }
    },
    activeRetryModal() {
      this.showRetryModal = true;
      this.disabled = true;
    },
    handleRetryButton() {
      this.showRetryModal = false;
      this.disabled = false;
      this.showModal = false;
      this.retries += 1;
      this.retriesGlobal += 1;
    },
    async handleNextButton() {
      IndexedDB.animationTrivia();
      const content = document.querySelector('.modal_next');
      setTimeout(() => {
        content.classList.remove('animation-trivia', 'show');
      }, 850);
      if (this.counter < this.questionsAmount) {
        this.setCounter();
        setTimeout(() => {
          this.showModal = false;
        }, 1000);
        this.retries = 0;
        this.disabled = false;
      } else {
        this.endTrivia();
      }
    },
    async endTrivia() {
      // Process database
      const indexed = process.env.VUE_APP_INDEXED_DB;

      const endTime = new Date();
      // in ms
      let timeDiff = endTime - this.startTime;
      // strip the ms
      timeDiff /= 1000;

      // get seconds
      const timeElapsed = Math.round(timeDiff);

      await IndexedDB.updateEntries({
        name: indexed,
        user: this.uuid,
        data: {
          completionReason: 'finished',
          qrShown: 1,
          timeElapsed,
          retries: 0,
        },
      });
      this.$store.commit('setUUID', null);
      this.$store.commit('setStartTime', null);
      setTimeout(() => {
        this.$router.push('/winner');
      }, 1000);
    },
    setCounter() {
      this.counter += 1;
      this.retries += 1;
    },
  },
  watch: {
    showRetryModal() {
      this.firsttime = false;
    },
  },
  async mounted() {
    this.firsttime = true;
    // Process database
    const indexed = process.env.VUE_APP_INDEXED_DB;
    const AllData = await IndexedDB.getIndexedDB(indexed);
    const { completionReason } = AllData.filter((word) => word.uuid === this.uuid)[0] || {};

    if (completionReason === '') {
      const {
        questions_to_show: questionsToShow,
        random_questions: randomQuestions,
      } = await IndexedDB.getIndexedDB('SETTINGS') || {};

      const questions = await IndexedDB.getIndexedDB('QUESTIONS');

      if (questionsToShow) {
        this.questionsAmount = parseInt(questionsToShow, 10);
      } else {
        this.questionsAmount = 4;
      }

      if (randomQuestions) {
        // Shuffle the array
        const shuffled = questions.sort(() => 0.5 - Math.random());

        // Get the first this.questionsAmount
        this.questions = shuffled.slice(0, this.questionsAmount);
      } else {
        this.questions = questions;
      }
    }
  },
};
</script>
<!-- eslint-disable max-len -->
<template>
  <div class="container-trivia">
    <img class="aperol_logo" src="../../public/img/images/aperol_logo.png" alt="aperol logo"/>

    <RestartButton
      @restart-trivia="restartTrivia"
      backgroundColor="#0042B9"
      fontColor="#FFF"
    />

    <trivia-question
      v-if="questions[counter - 1]"
      @active-modal="activeModal"
      @active-retry-modal="activeRetryModal"
      :disabled="disabled"
      :questionData="questions[counter - 1]"
      :retries="retries"
      :retriesGlobal="retriesGlobal"
    />
    <div class="fixed-bottom">
      <div class="pages_counter">
        <span style="text-align: left; font-size: 23px;">
          {{ counter.toString().padStart(2, '0') }}
        </span>
        <span style="text-align: left; font-size: 18px;">
          /{{ this.questionsAmount.toString().padStart(2, '0') }}
        </span>
      </div>

      <section class="tabs">
        <div class="background" :style="cssVars"></div>
        <div
          v-for="index in questionsAmount"
          :key="index"
          class="tab"
          :class="counter >= index ? 'active' : ''"
        ></div>
      </section>
    </div>
  </div>
  <div class="modal_next animate__animated animate__delay-0.5s animate__fadeInUp animate-retry"
       :class="showModal ? 'show' : ''">
    <img v-show="isCorrect" src="../../public/img/images/check-A.svg" alt="star_icon">
    <img v-show="!isCorrect" src="../../public/img/images/Wrong-A.svg" alt="star_icon">
    <p>
      {{ modalText }}
    </p>
    <button @click="handleNextButton">NEXT</button>
  </div>
  <div v-show="showRetryModal" :class="{
    'animate__animated animate__delay-0.5s animate__fadeInUp animate-retry': !firsttime && showRetryModal,
    'animate__animated animate__fadeOutUp animate-retry-reverse': !firsttime && !showRetryModal,
    'hide': firsttime,
    }" class="modal_retry">
    <span class="title1">
      Oops! We think you meant to tap
    </span>
    <span class="title2">
      the correct response instead.
    </span>
    <button @click="handleRetryButton">TRY AGAIN</button>
  </div>
</template>

<style lang="scss" scoped>
.container-trivia {
  max-width: 1112px;
  margin: auto;
  padding-top: 55px;
}

.show {
  display: grid !important;
}

.hide {
  display: none !important;
}

.modal_retry {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  place-items: center;
  z-index: 1;
  left: 0!important;
  right: 0!important;
  bottom: 0!important;
  top: 0 !important;
  background: linear-gradient(
    90deg, rgba(10, 19, 73, 0.7) -13.18%,
    rgba(0, 66, 185, 0.7) 48.12%,
    rgba(0, 189, 211, 0.7) 114.45%);

  span {
    width: 885px;
    font-family: 'SKYY MONO MEDIUM';
    font-style: normal;
    font-size: 43.3359px;
    line-height: 109.64%;

    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  .title2 {
    font-family: 'SKYY MONO MEDIUM';
  }

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    width: 214px;
    height: 49px;

    background: #0A1349;
    box-shadow: 0px 3.77673px 7.55347px #0A1349;
    border-radius: 51px;
    color: #FFFFFF;
    font-size: 22px;
    font-family: 'SKYY MONO BOLD';

    margin-top: 30px;

    &:hover {
      color: #0A1349;
      background: rgba(255, 242, 237, 0.76);
    }
  }

}

.modal_next {
  height: 180px;
  width: 100%;
  position: absolute;
  display: none;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
  z-index: 1;
  bottom: 0;

  img {
    width: 105px;
    margin-left: 135px;
  }

  p {
    width: 425px;
    color: #FFFFFF;
    // font-family: 'Maison Neue Book';
    // font-size: 16px;
    // margin-bottom: 9%;
    font-size: 16px;
    // font-weight: 500;
    text-align: left;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 180px;
    background: linear-gradient(90deg, #0A1349 -13.18%, #0042B9 48.12%, #00BDD3 114.45%);
    transform-origin: left;
    z-index: -1;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 180px;
    background: linear-gradient(90deg, #0A1349 -13.18%, #0042B9 48.12%, #00BDD3 114.45%);
    transform-origin: left;
    // transform: skewY(356.7deg);
    z-index: -1;
  }

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    width: 214px;
    height: 49px;

    background: #0A1349;
    box-shadow: 0px 3.77673px 7.55347px #0A1349;
    border-radius: 51px;
    color: #FFFFFF;
    font-size: 22px;
    font-family: 'SKYY MONO BOLD';

    // margin-bottom: 9%;

    &:hover {
      background: rgba(255, 242, 237, 0.76);
    }
  }
}

.aperol_logo {
  position: absolute;
  top: 25px;
  left: 55px;
}

.decoration {
  max-width: 100%;
  @media only screen and (min-width: 1025px) and (orientation: landscape) {
    margin-top: 20px;
  }
}

.fixed-bottom {
  position: fixed;
  bottom: 25px;
  left: 30px;
  right: 30px;
}
.pages_counter {
  display: flex;
  align-items: baseline;
  margin-bottom: 5px;
  font-family: 'SKYY MONO BOLD';
  z-index: -2;
  position: sticky;

  &:first-child {
    font-size: 23px;
  }

  &::last-child {
    font-size: 18px;
  }
}

section.tabs {
  display: grid;
  grid-template-columns: repeat(v-bind(questionsAmount), 1fr);
  place-items: center;
  column-gap: 10px;
  position: relative;

  // position: relative;
  // background: linear-gradient(90deg, #0A1349 -13.18%, #0042B9 48.12%, #00BDD3 114.45%);

  @media (orientation: portrait) {
    grid-template-columns: repeat(2, 1fr);
  }

  margin-bottom: 15px;

  .background {
    --background-width: 100%;
    position: absolute;
    height: 100%;
    width: var(--background-width);
    // background-color: red;
    background: linear-gradient(90deg, #0A1349 -13.18%, #0042B9 48.12%, #00BDD3 114.45%);

    left: 0;
  }

  .tab {
    background: rgba(0, 66, 185, 0.3);
    opacity: 1;
    width: 100%;
    height: 21px;
    // z-index: -1;
    position: relative;
    background-color: #E0E8F8;
    // background-color: transparent;

    &::after {
      content: '';
      height: 100%;
      width: 10px;
      background-color: white;
      right:-10px;
      position: absolute;
      z-index: 1;
    }
    &:last-child {
      &::after {
        content: none;
      }
    }
  }

  .active {
    background-color: transparent;
    // background: linear-gradient(90deg, #0A1349 -13.18%, #0042B9 48.12%, #00BDD3 114.45%);
    opacity: 1;

    // background-clip: text;
    // -webkit-background-clip: text !important; /* for Safari/WebKit */
  }
}
</style>
