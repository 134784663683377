<!-- eslint-disable max-len -->
<template>
  <div class="age-view-content bg-content">
    <RestartButton
      @click="restartTrivia"
      backgroundColor="#FFFFFF"
      fontColor="#0042B9"
      :text="restartButtonText"
    />
    <div class="form">
      <div class="head">
        <img src="../../public/img/images/logo_site.svg" alt="">
        <h2>
          You win! We are bubbling with joy!
        </h2>
        <h3>
          <b>Scan the code </b> to get your special offer.
        </h3>
      </div>
      <div class="body">
        <!-- <QRCode v-if="false" /> -->
        <!-- <h1>{{ currentInstance }}</h1> -->
        <img class="qr-code" v-if="currentInstance === 'trivia'" src="/img/images/skyy_trivia_qr_prod_trivia.png" alt="QR Code TRIVIA">
        <img class="qr-code" v-if="currentInstance === 'form'" src="/img/images/skyy_trivia_qr_prod_form.png" alt="QR Code FORM">

        <p class="bold">
          If you have any issue scanning or loading the page, take a <br> photo and show it to one of our SKYY ambassadors.
        </p>
        <p>
          SKYY® Vodka distilled from grain. 40% alc./vol. (80 Proof). <br>
          ©2023 Campari America, New York, NY. Please enjoy responsibly.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import RestartButton from '@/components/RestartButton.vue';
// import QRCode from '@/components/QRCode.vue';

export default {
  name: 'WinnerView',
  components: {
    RestartButton,
    // QRCode,
  },
  data() {
    return {
      timer: null,
      timeout: 30,
      remaining: 30,
    };
  },
  methods: {
    restartTrivia() {
      this.$router.push('/');
    },
    formatTime(numSeconds) {
      const minutes = Math.floor(numSeconds / 60);
      const seconds = numSeconds % 60;
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
      // if (minutes > 0) {
      //   return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
      // }
      // return `${seconds}`;
    },
    startTimer() {
      if (this.timer == null) {
        this.resetTimer();
      }
      this.timer = setInterval(() => {
        this.remaining -= 1;
        if (this.remaining <= 0) {
          this.remaining = this.timeout;
          this.resetTimer();
          this.$router.push('/');
        }
      }, 1000);
    },
    resetTimer() {
      if (this.timer == null) {
        return;
      }
      clearInterval(this.timer);
      this.timer = null;
    },
  },
  computed: {
    restartButtonText() {
      const { remaining, formatTime } = this;
      const remainingString = formatTime(remaining);
      return `Restart ${remainingString}`;
    },
    currentInstance() {
      const { VUE_APP_INDEXED_DB } = process.env;
      if (VUE_APP_INDEXED_DB.toLowerCase().trim().indexOf('optin') > -1) {
        return 'form';
      }
      return 'trivia';
    },
  },
  mounted() {
    this.startTimer();
  },
  beforeUnmount() {
    this.resetTimer();
  },
};
</script>

<style lang="scss">
.age-view-content {
  .form {
    .body {
      gap: 0;
    }
  }

  .qr-code {
    width: 332px;
  }

  p.light {
    font-family: 'Maison Neue Book' !important;
  }
  .el-message-box__title {
    padding-left: 10px;

    span {
      font-family: "Maison Neue Book", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 48px;
      text-transform: uppercase;
    }
  }

  .el-message-box__message {
    text-align: center;

    p {
      font-family: "Maison Neue Book", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      text-transform: uppercase;
    }
  }

  .el-message-box__btns {
    .el-button {
      font-family: "SKYY MONO BOLD", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 37px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #FFFFFF;
      background: #0A1349;
      box-shadow: 0px 6.20541px 12.4108px #0A1349;
      border-radius: 54px;
      border: 1px solid;
    }
  }

  h3{
    font-family: 'Maison Neue Book';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 140%;
    text-align: center;
    color: #FFFFFF;
    b{
      font-weight: bold;
    }
  }

  h2{
    margin-bottom: 0;
    padding: 0;
  }

  h3{
    margin: 0;
    padding: 0;
  }

  .body{
    p{
      max-width: 499px;
      font-family: 'Maison Neue Book';
      font-style: normal;
      font-weight: 650;
      font-size: 18px;
      line-height: 140%;
      text-align: center;
      color: #FFFFFF;
    }
  }

  .button_restart {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    position: absolute;
    width: 129px;
    height: 42px;
    right: 55px;
    top: 25px;

    background: #0A1349;
    border-radius: 58px;
    color: #FFFFFF;
    font-size: 16px;
    font-family: 'SKYY MONO BOLD';
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;

    :hover{
      opacity: 0.8;
    }
  }
}

</style>
